.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 670px;
  height: 100%;
  background-color: #f4f4f4;
  transition: transform 0.3s ease;
  transform: translateX(100%); /* 초기에는 화면 밖으로 숨겨져 있어야 함 */
  overflow-y: auto;
}

.open {
  transform: translateX(0); /* open 클래스가 적용되면 화면 안으로 이동 */
}
