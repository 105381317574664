/* ApprovalProcess.module.css */
.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  /* overflow-y: auto; */
  font-size: smaller;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0px 10px;
}

.modalBody {
  max-height: 80vh;
  overflow-y: auto;
}

.infoSection {
  display: flex;
  flex-direction: column;
  border: solid #ddd 1px;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.infoRow {
  display: flex;
  margin-bottom: 10px;
}

.infoRow .label {
  font-weight: bold;
  width: 12%;
  min-width: 70px;
  text-align: right;
  padding-right: 10px;
}

.infoRow .value {
  width: 88%;
}

.infoRow input {
  width: 100%;
}

.tableContainer {
  border: solid #ddd 1px;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.approvalTable {
  width: 100%;
  border-collapse: collapse;
}

.approvalTable th {
  padding: 4px;
  border: 1px solid grey;
  text-align: center;
  background-color: lightgray;
}

.approvalTable td {
  padding: 4px;
  border: 1px solid grey;
  text-align: center;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.approveButton {
  background-color: #007bff;
  color: white;
}

.cancelButton {
  background-color: #ffc107;
  color: white;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxContainer input[type="checkbox"] {
  width: 18px; /* 체크박스 크기 조정 */
  height: 18px; /* 체크박스 크기 조정 */
  margin-left: 5px; /* 텍스트와 체크박스 사이의 간격 */
}
