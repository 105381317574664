.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  font-size: smaller;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90vw;
  max-height: 80vh; /* 모달 높이를 뷰포트의 80%로 제한 */
  overflow: hidden; /* 가로/세로 스크롤 숨김 */
  display: flex;
  flex-direction: column;
}

.modalContent {
  flex: 1;
  overflow: auto; /* 가로 및 세로 스크롤 모두 활성화 */
  margin-top: 10px;
}

.table-responsive {
  overflow-x: auto; /* 가로 스크롤 활성화 */
  white-space: nowrap; /* 테이블 셀 줄바꿈 방지 */
}
