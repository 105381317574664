.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContainer {
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.closeButton,
.submitButton {
  margin-top: 10px;
}

.textarea {
  width: 100%;
  height: 100px;
}
