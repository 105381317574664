.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.gradeTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: smaller;
}

.gradeTable th,
.gradeTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.gradeTable th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.gradeTable tr:nth-child(even) {
  background-color: #fafafa;
}

.gradeTable select {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
