.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
}

.headerRow {
  background-color: #e5eeff;
}

.th {
  padding: 8px 8px; /* 상하 패딩 축소 */
  text-align: left;
  font-weight: 600;
  color: #475569;
  vertical-align: middle;
  border-bottom: 2px solid #e2e8f0;
}

.tr:nth-child(even) {
  background-color: #f8fafc;
}

.tr:nth-child(odd) {
  background-color: #ffffff;
}

.tr:hover {
  background-color: #f1f5f9;
}

/* 클릭 가능한 행 스타일 */
.clickableRow {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickableRow:hover {
  background-color: #f0f7ff; /* 연한 파란색 배경으로 hover 효과 */
}

/* 선택된 행 스타일 */
.selectedRow {
  background-color: #e6f0ff !important; /* 더 강조된 배경색 */
  border-left: 3px solid #3b82f6; /* 왼쪽 파란색 테두리 */
}

.td {
  padding: 8px 8px; /* 상하 패딩 축소 */
  vertical-align: middle;
  border-bottom: 1px solid #e2e8f0;
  color: #334155;
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #cbd5e1;
  border-radius: 6px;
  transition: all 0.2s;
}

.input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
}

.textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #cbd5e1;
  border-radius: 6px;
  resize: vertical;
  transition: all 0.2s;
}

.textarea:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
}

.tableContainer {
  width: 100%;
  overflow: hidden;
}

.pagination {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  margin-top: 8px;
  width: 100%;
}

.paginationStats {
  font-size: 13px;
  color: #64748b;
  grid-column: 1;
  justify-self: start;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  grid-column: 2;
  justify-self: center;
}

.paginationButton {
  padding: 6px 12px;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  color: #334155;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.paginationButton:hover:not(:disabled) {
  background-color: #f8fafc;
  border-color: #94a3b8;
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.paginationInfo {
  padding: 6px 12px;
  color: #334155;
  font-size: 14px;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.sortIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.9;
  margin-left: 4px;
}

.sortIconInactive,
.sortIconActive {
  font-size: 10px;
  margin: 1px 0;
}

.sortIconInactive {
  color: #cbd5e1;
}

.sortIconActive {
  color: #4f46e5;
}

.clearSearchButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-80%);
  background: none;
  border: none;
  font-size: 22px;
  font-weight: bold;
  color: #64748b;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearSearchButton:hover {
  color: #334155;
}

/* 테이블 컨트롤 컨테이너 */
.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 내보내기 버튼 스타일 */
.exportButton {
  background-color: #2e7d32; /* 포레스트 녹색 */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.exportButton:hover {
  background-color: #1b5e20; /* 더 진한 포레스트 녹색 */
}

.exportButton:before {
  content: "⬇️";
  font-size: 16px;
}
