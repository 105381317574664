.fade-enter {
  opacity: 0;
  transform: translateX(100%); /* 오른쪽에서 시작 */
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0); /* 원래 위치로 이동 */
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  display: none;
  /* opacity: 1;
  transform: translateX(0); */
}
.fade-exit-active {
  display: none;
  /* opacity: 0;
  transform: translateX(100%); 
  transition: opacity 300ms, transform 300ms; */
}
