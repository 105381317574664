.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #0056b3;
  margin-top: 10px;
  margin-left: 20px;
}

.message {
  margin-right: 8px;
}

.dots {
  display: flex;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #0056b3;
  border-radius: 50%;
  animation: bounce 1s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
