body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}

/* input 옆에 화살표 안 보이게 하는 것. 이건 보류
스크롤로 안 변하게 하는 건 자바스크립트로 넣었음

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  max-width: 1536px;
}

.basicWidth {
  width: 97vw;
  max-width: 1536px;
  align-self: center;
}

.text-brand {
  color: #f96162;
}
.x-underline {
  text-decoration: none;
}

.input-like-span {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  display: inline-block;
  width: 100%;
  min-width: 20px;
}

.input-like-span:focus {
  border: none;
  outline: solid;
}

.select-like-span {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  display: inline-block;
  width: 100%;
  min-width: 30px;
}

.select-like-span:focus {
  border: none;
  outline: solid;
}

.modalForSpinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spinnerForLoading {
  z-index: 1000;
  background-color: #ffffff; /* 흰색 배경 */
  padding: 5px; /* 스피너 주변에 여백을 추가 */
  border-radius: 10px; /* 경계 모서리를 둥글게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */
}

.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.modalForUnit {
  border-radius: 5px;
  padding: 2rem;
  background-color: white;
  width: 30em;
}

.modal-header {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
}

.modal-content {
  margin-bottom: 2rem;
  max-height: calc(
    100vh - 140px
  ); /* 모달 창의 최대 높이를 설정. 100vh는 뷰포트의 높이를 나타냄. 200px는 모달 창의 상하 여백을 고려한 값. */
  overflow-y: auto; /* 세로 방향으로 스크롤이 필요할 때만 스크롤바가 나타나도록 설정 */
  overflow-x: hidden; /* 가로 방향으로는 스크롤바가 나타나지 않도록 설정 */
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media print {
  body {
    margin: 0;
    font-size: 12pt;
    color: black;
    background: white;
  }
  .page-break {
    page-break-after: always;
  }
  .page {
    page-break-inside: avoid;
    position: relative;
    padding: 20mm;
  }
  .hide-on-print {
    display: none;
  }
}

.highlight-validate {
  border: 2px solid red;
  box-shadow: 0 0 5px red;
}

.datagrid-wrap-cell {
  white-space: normal !important;
  line-height: 1.2 !important;
  word-wrap: break-word;
}
