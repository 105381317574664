.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  background-color: white;
}

.headerRow {
  background-color: #f3f3f3;
  border-bottom: 1px solid #d0d0d0;
}

.th {
  padding: 8px 4px;
  text-align: center;
  font-weight: 600;
  color: #333;
  border-right: 1px solid #e0e0e0;
  position: relative;
}

.th:last-child {
  border-right: none;
}

/* 열 구분선 스타일 */
.th::after {
  content: "";
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  border-right: 1px solid #bdbdbd;
}

.th:last-child::after {
  display: none;
}

.tr {
  border-bottom: 1px solid #e0e0e0;
  background-color: white; /* 모든 행에 흰색 배경 적용 */
}

.tr:hover {
  background-color: #f0f7ff;
}

/* 클릭 가능한 행 스타일 */
.clickableRow {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickableRow:hover {
  background-color: #f0f7ff; /* 연한 파란색 배경으로 hover 효과 */
}

/* 선택된 행 스타일 */
.selectedRow {
  background-color: #e6f0ff !important; /* 더 강조된 배경색 */
  border-left: 3px solid #3b82f6; /* 왼쪽 파란색 테두리 */
}

.td {
  padding: 6px 8px;
  border-right: 1px solid #e0e0e0;
  vertical-align: middle;
  color: #333;
}

.td:last-child {
  border-right: none;
}

.input {
  width: 100%;
  padding: 5px;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  transition: border 0.2s;
}

.input:focus {
  border: 2px solid #4285f4;
  outline: none;
}

.textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #bdbdbd;
  resize: vertical;
  background-color: #fff;
  transition: border 0.2s;
}

.textarea:focus {
  border: 2px solid #4285f4;
  outline: none;
}

.tableContainer {
  width: 100%;
  overflow: hidden;
}

.pagination {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  padding-top: 12px;
}

.paginationStats {
  font-size: 13px;
  color: #555;
  grid-column: 1;
  justify-self: start;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  grid-column: 2;
  justify-self: center;
}

.paginationButton {
  padding: 6px 12px;
  background-color: #f3f3f3;
  border: 1px solid #bdbdbd;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.paginationButton:hover:not(:disabled) {
  background-color: #e0e0e0;
  border-color: #9e9e9e;
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.paginationInfo {
  padding: 6px 12px;
  color: #333;
  font-size: 14px;
  border: 1px solid transparent;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.sortIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.9;
  margin-left: 4px;
}

.sortIconInactive,
.sortIconActive {
  font-size: 10px;
  margin: 1px 0;
}

.sortIconInactive {
  color: #c0c0c0;
}

.sortIconActive {
  color: #008000;
}

.clearSearchButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-80%);
  background: none;
  border: none;
  font-size: 22px;
  font-weight: bold;
  color: #808080;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearSearchButton:hover {
  color: #333333;
}

/* 테이블 컨트롤 컨테이너 */
.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 내보내기 버튼 스타일 */
.exportButton {
  background-color: #2e7d32; /* 포레스트 녹색 */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.exportButton:hover {
  background-color: #1b5e20; /* 더 진한 포레스트 녹색 */
}

.exportButton:before {
  content: "⬇️";
  font-size: 16px;
}
