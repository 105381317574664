.tableStyle th {
  padding: 0px;
  text-align: center;
  background-color: #f0f0f0;
}

.tableStyle td {
  padding: 0px;
  text-align: center;
}

.tableStyle {
  font-size: 12px;
  width: 100%;
}
