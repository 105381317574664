/* 테이블 테두리 색상 */
.thisTable {
  border: 1px solid #ddd;
}

.thisTable th,
.thisTable td {
  border: 1px solid #ddd;
}

/* 테이블 헤더 스타일 */
.thisTable th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

/* 기본 텍스트 색상 */
.thisTable td {
  color: #333;
}
