.toastCenterBottom {
  position: fixed;
  bottom: 50px; /* 하단 위치로 변경 */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #4a90e2; /* 푸른 계열 색상으로 변경 */
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
