/* LogsModal.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  min-width: 400px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.modalContent {
  margin-top: 10px;
}

.logsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.logItem {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
