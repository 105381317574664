.tableContainer {
  font-family: var(--bs-font-sans-serif);
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  overflow: hidden;
}

.table {
  width: 100%;
  margin-bottom: 0;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  border-collapse: collapse;
}

.headerRow {
  background-color: #0d6efd;
  color: white;
  border-bottom: 2px solid #dee2e6;
}

.th {
  padding: 0.75rem;
  text-align: center;
  font-weight: 600;
  border: 1px solid #dee2e6;
  vertical-align: middle;
  color: white;
}

.tr {
  border-bottom: 1px solid #dee2e6;
}

.tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

.tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* 클릭 가능한 행 스타일 */
.clickableRow {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickableRow:hover {
  background-color: #f0f7ff; /* 연한 파란색 배경으로 hover 효과 */
}

/* 선택된 행 스타일 */
.selectedRow {
  background-color: #e6f0ff !important; /* 더 강조된 배경색 */
  border-left: 3px solid #3b82f6; /* 왼쪽 파란색 테두리 */
}

.td {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  vertical-align: middle;
}

.input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textarea:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.sortableHeader {
  cursor: pointer;
}

.sortableHeader:hover {
  background-color: #0b5ed7;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sortIconContainer {
  display: inline-flex;
  flex-direction: column;
  margin-left: 0.25rem;
  line-height: 0.5;
}

.sortIconActive {
  color: white;
  font-size: 0.75rem;
}

.sortIconInactive {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-top: 0;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

.paginationStats {
  color: #6c757d;
  font-size: 0.875rem;
}

.paginationControls {
  display: flex;
  align-items: center;
}

.paginationButton {
  padding: 0.25rem 0.5rem;
  margin: 0 0.125rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  color: #0d6efd;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.paginationButton:hover:not(:disabled) {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #0a58ca;
}

.paginationButton:disabled {
  color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}

.paginationInfo {
  margin: 0 0.5rem;
  color: #212529;
}

.searchContainer {
  position: relative;
  margin-bottom: 1rem;
}

.searchInput {
  padding: 0.375rem 0.75rem;
  padding-right: 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.searchInput:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.clearSearchButton {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.25rem;
  line-height: 1;
  color: #6c757d;
  cursor: pointer;
}

.clearSearchButton:hover {
  color: #212529;
}

.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exportButton {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid #0d6efd;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #0d6efd;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.exportButton:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.noData {
  padding: 2rem;
  text-align: center;
  color: #6c757d;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
