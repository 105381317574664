.sideMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
}

@media (max-width: 768px) {
  .sideMenu {
    flex-direction: row; /* 화면이 작아지면 플렉스 방향 변경 */
    height: auto; /* 화면이 작아지면 높이 조정 */
  }
}
