.table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 97%;
}

.table td {
  border: 1px solid black;
  text-align: center;
  padding: 1px;
  font-size: 11px;
  vertical-align: top; /* 상단 정렬 */
}

.table th {
  font-size: 12px;
  border: 1px solid black;
  text-align: center;
  padding: 5px;
  background-color: #d9d9d9;
}
